import { useContext, useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiHome, FiMinus, FiPlus, FiTrash } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import { ButtonComponentLite } from "../../components/button/button-component";
import { PaymentSection } from "./section/PaymentSection";
import { NavbarSecondaryComponent } from "../../components/navbar/navbar-component";
import { PopUpCloseSection } from "./section/PopUpCloseSection";
import { PaymentSuccessSection } from "./section/PaymentSuccessSection";
import { GET_TENANTS } from "src/graphql/query/Tenant";
import { useMutation, useQuery } from "@apollo/client";
import { MenuComponent } from "./component/MenuComponent";
import { ChooseLevelNumberSection } from "./section/ChooseLevelNumberSection";
import { BillSection } from "./section/BillSection";
import { UPDATE_TABLE_NUMBER } from "src/graphql/mutation/UpdateTableNumber";
import { EmptyComponent } from "./component/EmptyComponent";
import { LoadingComponent } from "../../components/loading/loading-component";
import { ErrorComponent } from "../../components/error/error-component";
import { CustomerSection } from "./section/CustomerSection";
import { UserContext } from "../../context/UserContext";
import { NotionComponent } from "../../components/notion";
import { PrinterServices } from "src/service/PrinterServices";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { OperatorContext } from "../../context/OperatorContext";
import { PrintSection } from "./section/PrintSection";
import { ChooseTableNumberSection } from "./section/ChooseTableNumberSection";
import { LanguageContext } from "../../context/LanguageContext";
import language from "../../language/data.json";
import audioThankYou from "./../../assets/audio/thank-you.m4a";
import { TimeServices } from "../../../../service/TimeServices";
import { SecretService } from "../../../../service/SecretService";

export function OrderListPage() {
  const navigate = useNavigate();
  const { typeService } = useParams();
  const printerServices = new PrinterServices();

  const { lang } = useContext(LanguageContext);
  const { cart, setCart } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);
  const { operator, setOperator } = useContext(OperatorContext);

  const [refClose, setRefClose] = useState(null);
  const [refCustomer, setRefCustomer] = useState(null);
  const [refLevelNumber, setRefLevelNumber] = useState(null);
  const [refTableNumber, setRefTableNumber] = useState(null);

  const [refBill, setRefBill] = useState(null);
  const [refPayment, setRefPayment] = useState(null);
  const [refPaymentSuccess, setRefPaymentSuccess] = useState(null);

  const [levelNumber, setLevelNumber] = useState(null);
  const [tableNumber, setTableNumber] = useState(null);

  const {
    loading: loadingTenants,
    error: errorTenants,
    data: dataTenants,
    refetch: refetchTenants,
  } = useQuery(GET_TENANTS);

  const [
    editTableNumber,
    {
      loading: loadingEditTableNumber,
      error: errorEditTableNumber,
      data: dataEditTableNumber,
    },
  ] = useMutation(UPDATE_TABLE_NUMBER);

  const updateTableNumber = async (resOrder) => {
    const res = await editTableNumber({
      variables: {
        input: {
          orderCustomerId: resOrder.id,
          tableNumber: tableNumber,
        },
      },
    });

    if (loadingEditTableNumber == false && errorEditTableNumber == undefined) {
      return true;
    }
  };

  const handlePrint = async ({ orderId, typePayment, moneyAmount }) => {
    let printing = ["DISMORE_CUSTOMER1", "DISMORE_KITCHEN1"];

    const machine = operator.userRole == "tenant" ? "Self Service" : "Cashier";

    const subTotal = cart.reduce(
      (temp, x) => (x.buy == true ? temp + x.originalPrice * x.amount : temp),
      0,
      0
    );

    const tempOrder = cart
      .filter((item) => item.buy == true)
      .map((item) => {
        return {
          name: item.name,
          amount: item.amount,
          notes: item.notes,
          originalPrice: rupiahFormat(item.originalPrice),
          totalPrice: rupiahFormat(item.originalPrice * item.amount),
        };
      });

    const time = new Date().toLocaleString("id-ID", {
      timeZone: "Asia/Jakarta",
    });

    const encodedPayload = await SecretService.safeURLJSONEncode({
      created_at: new TimeServices().setLocale("id").getISO8601(),
    });

    const repeatOrderUrl =
      "https://qr-demo.dismore.tech/" +
      `${user.name}/${levelNumber}/${tableNumber}?token=${encodedPayload}`;

    // const repeatOrderUrl =
    //   "https://qr-demo.dismore.tech/" +
    //   `${user.name}/${levelNumber}/${tableNumber}`;

    const tax =
      cart.reduce(
        (temp, x) => (x.buy == true ? temp + x.price * x.amount : temp),
        0,
        0
      ) - subTotal;

    const json = {
      printing: printing,
      moneyAmount: rupiahFormat(parseInt(moneyAmount)),
      returnAmount:
        typePayment == "QRIS"
          ? rupiahFormat(0)
          : rupiahFormat(parseInt(moneyAmount) - (subTotal + tax)),
      levelNumber: levelNumber,
      tableNumber: tableNumber,
      idOrderCustomer: orderId,
      typeService: typeService,
      typePayment: typePayment,
      user: user,
      orderTime: time,
      machine: machine,
      orders: tempOrder,
      subTotal: rupiahFormat(subTotal),
      discount: rupiahFormat(0),
      tax: rupiahFormat(tax),
      total: rupiahFormat(subTotal + tax),
      repeatOrderUrl: repeatOrderUrl,
    };

    const res = await printerServices.submitPrintJob({ json, id: orderId });
  };

  if (loadingTenants) return <LoadingComponent />;
  if (errorTenants) return <ErrorComponent />;

  return (
    <div className="h-full relative overflow-hidden flex flex-col items-center">
      {refClose ? (
        <PopUpCloseSection
          callback={() => {
            setRefClose(null);
          }}
        />
      ) : null}

      {refCustomer ? (
        <CustomerSection
          handleCancel={() => {
            setRefCustomer(null);
          }}
          callback={async (name) => {
            setUser({ name: name });
            setRefCustomer(null);
            setRefLevelNumber(true);
          }}
        />
      ) : null}

      {refLevelNumber ? (
        <ChooseLevelNumberSection
          callback={(value) => {
            setLevelNumber(value);
            setRefLevelNumber(null);
            setRefTableNumber(true);
          }}
          handleCancel={() => {
            setRefLevelNumber(null);
          }}
        />
      ) : null}

      {refTableNumber ? (
        <ChooseTableNumberSection
          callback={(value) => {
            setTableNumber(value);

            const tempCart = cart.filter((x) => x.buy == true);
            const tempCart2 = [];
            {
              dataTenants.tenants.map((tenant, index) => {
                return tenant.menus.map((menu, i) => {
                  if (tempCart.filter((x) => x.menuId == menu.id).length > 0) {
                    tempCart2.push(
                      ...tempCart.filter((x) => x.menuId == menu.id)
                    );
                  }
                });
              });
            }

            setRefTableNumber(null);
            setCart(tempCart2);
            setRefBill(true);
          }}
          handleCancel={() => {
            setRefTableNumber(null);
          }}
        />
      ) : null}

      {refBill ? (
        <BillSection
          levelNumber={levelNumber}
          name={user.name}
          typeService={typeService}
          cart={cart}
          callback={() => {
            setRefBill(null);
            setRefPayment(true);
          }}
          handleCancel={() => {
            setRefBill(null);
          }}
        />
      ) : null}

      {refPayment ? (
        <PaymentSection
          tableNumber={tableNumber}
          typeService={typeService}
          cart={cart}
          handlePrint={({ orderId, typePayment, moneyAmount }) => {
            handlePrint({ orderId, typePayment, moneyAmount });
          }}
          action={async (resOrder) => {
            const res = await updateTableNumber(resOrder);
            if (res) {
              setRefPaymentSuccess(true);

              const audio = new Audio(audioThankYou);
              audio.play();

              setTimeout(() => {
                navigate(`/rating/${resOrder.id}`);
              }, 2000);
            }
          }}
          handleCancel={() => {
            setRefPayment(null);
          }}
        />
      ) : null}

      {refPaymentSuccess ? <PaymentSuccessSection /> : null}

      <div className="w-11/12 flex justify-center">
        <NavbarSecondaryComponent
          title={language.orderList.navbar[lang]}
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            setRefClose(true);
          }}
        />
      </div>

      <div className="w-full bg-gray-200 grow flex flex-col gap-2 overflow-scroll">
        {cart.length == 0 ? <EmptyComponent /> : null}
        {dataTenants.tenants.map((tenant, index) => {
          return tenant.menus.map((menu, i) => {
            if (cart.filter((x) => x.menuId == menu.id).length > 0) {
              return <MenuComponent menu={menu} key={`menu_${menu.id}`} />;
            }
          });
        })}
        <div className="bg-slate-200 h-0.5 w-full"></div>
      </div>

      <div className="w-full bg-white flex items-center justify-between px-[75px] py-[35px]">
        <div className="">
          <p className="font-bold text-[24px]">
            {language.orderList.allItem[lang]}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <p className=" text-[20px]">{language.orderList.total[lang]}</p>
          <div className="mr-3">
            <h2 className="font-extrabold text-[35px] text-main whitespace-nowrap">
              {`${rupiahFormat(
                cart.reduce(
                  (temp, x) =>
                    x.buy == true ? temp + x.originalPrice * x.amount : temp,
                  0,
                  0
                )
              )} `}
            </h2>
          </div>
          <ButtonComponentLite
            title={language.orderList.checkout[lang]}
            handle={() => {
              if (cart.length > 0) setRefCustomer(true);
            }}
          />
        </div>
      </div>

      <NotionComponent />
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import home from "./../../assets/images/home-resize.jpg";
import { FiChevronRight, FiRefreshCcw } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import logoWhite from "./../../assets/images/logo-white.png";
import dismore from "./../../assets/images/dismore.svg";
import { CartContext } from "../../context/CartContext";
import { REACT_APP_VERSION } from "../../version";
import { NotionComponent } from "../../components/notion";
import language from "../../language/data.json";
import { LanguageContext } from "../../context/LanguageContext";
import imageQRWA from "./../../assets/images/qr-wa.png";

export function HomePage() {
  const navigate = useNavigate();

  const { cart, setCart } = useContext(CartContext);
  const { lang, setLang } = useContext(LanguageContext);
  const [refLang, setRefLang] = useState(false);
  const [refQRWA, setRefQRWA] = useState(false);

  useEffect(() => {
    setCart([]);
  }, []);

  return (
    <div className="h-full relative overflow-hidden flex flex-col justify-center">
      <div className="absolute h-full w-full z-0">
        <img src={home} alt="" className="w-full h-full" />
      </div>
      <div className="absolute h-full w-full z-0">
        <div className="w-full h-full bg-black bg-opacity-30"></div>
      </div>
      <div className="lg:h-3/4 w-full relative text-white flex flex-col justify-center">
        <div className="lg:px-[100px] px-16 flex items-center gap-4">
          {/* <img src={logoWhite} className="lg:h-[164px] h-[100px]" /> */}
          <img src={dismore} className="lg:h-[200px] h-[120px]" />
        </div>

        <div className="lg:mb-[150px] mb-[60px] bg-opacity-50 w-full lg:px-32 px-16 py-7 mt-10">
          <h2 className="font-semibold lg:text-[80px] text-[32px]">
            {language.home.welcome[lang]} <br></br>
            <span className="text-main font-bold lg:text-[110px] text-[72px]">
              DISMORE APP
            </span>
          </h2>
          <h4 className="lg:text-[50px] text-[32px] font-semibold italic mt-4">
            #tempatnyaNgopi
          </h4>
          <p className="lg:text-[36px] text-[24px] font-semibold mt-2">
            v {REACT_APP_VERSION}
          </p>

          {/* <button
            onClick={() => {
              throw new Error("My first Sentry error!");
            }}
          >
            Break the world test
          </button> */}
        </div>
      </div>

      <div className="relative w-full grow">
        <div className="grid grid-cols-12">
          <div className="col-span-5 flex flex-col items-center">
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="rounded-full bg-main lg:p-14 p-8 text-center mb-5 shadow-xl text-white"
            >
              <FiRefreshCcw className="lg:scale-150" size={28} />
            </button>
            <h4 className="lg:text-[28px] text-[20px] text-center text-white font-medium w-56 text-cecnter">
              {language.home.refresh[lang]}
            </h4>
          </div>
          <div className="col-span-7 flex flex-col items-end">
            <button
              onClick={() => {
                navigate("/type-service");
              }}
              className="lg:py-8 py-6 pl-14 pr-5 w-full lg:mb-10 mb-5 lg:text-[44px] text-[28px] rounded-l-full bg-main text-white font-bold flex justify-between items-center"
            >
              {language.home.order[lang]}
              <div>
                <FiChevronRight />
              </div>
            </button>

            <button
              onClick={() => {
                setRefLang(true);
              }}
              className="lg:py-6 py-4 pl-14 pr-5 w-full lg:mb-10 mb-5 lg:text-[28px] text-[20px] rounded-l-full bg-secondary text-white font-bold flex justify-between items-center"
            >
              {language.home.language[lang]}
              <div>
                <FiChevronRight />
              </div>
            </button>

            <button
              className=" py-5 pl-14 pr-5 lg:text-[40px] text-[20px] font-medium italic underline text-white"
              onClick={() => {
                setRefQRWA(true);
              }}
            >
              {language.home.callForAssistance[lang]}
            </button>
          </div>
        </div>
      </div>
      {refLang ? (
        <div className="w-full h-[420px] bg-secondary absolute bottom-0 rounded-t-[80px] flex justify-center z-50">
          <div className="w-11/12">
            <h2 className="text-[36px] text-white text-center font-bold mt-10">
              {language.home.selectLanguage[lang]}
            </h2>

            <div className="mt-10 flex flex-col gap-4">
              {/* <ButtonLang
                title="Jawa"
                callback={() => {
                  setLang("jawa");
                  setRefLang(false);

                  localStorage.setItem("lang", "jawa");
                }}
              /> */}
              <ButtonLang
                title="Bahasa Indonesia"
                callback={() => {
                  setLang("id");
                  setRefLang(false);

                  localStorage.setItem("lang", "id");
                }}
              />
              <ButtonLang
                title="English"
                callback={() => {
                  setLang("en");
                  setRefLang(false);

                  localStorage.setItem("lang", "en");
                }}
              />
              <ButtonLang
                title="Mandarin"
                callback={() => {
                  setLang("cn");
                  setRefLang(false);

                  localStorage.setItem("lang", "cn");
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {refQRWA ? (
        <CardQRWA
          callback={() => {
            setRefQRWA(false);
          }}
        />
      ) : null}

      <div className="absolute bottom-0 w-full z-10">
        <NotionComponent />
      </div>
    </div>
  );
}

function ButtonLang({ title, callback }) {
  return (
    <button
      onClick={callback}
      className="bg-main w-full py-3 px-5 rounded-3xl text-white text-[24px] font-semibold"
    >
      {title}
    </button>
  );
}

function CardQRWA({ callback }) {
  return (
    <div className="absolute z-40 bg-black bg-opacity-80 w-full h-full flex flex-col justify-center items-center gap-4">
      <div className="text-center mb-5">
        <h2 className="text-white text-[48px] font-bold">QR WhatsApp</h2>
        <p className="text-white text-[32px] font-semibold">
          Scan QR code to call for assistance
        </p>
      </div>
      <img src={imageQRWA} />

      <div className="w-[350px] text-white mt-5">
        <button
          className="
        bg-main w-full py-3 rounded-3xl text-white text-[24px] font-semibold"
          onClick={callback}
        >
          Close QR WhatsApp
        </button>
      </div>
    </div>
  );
}
